import * as React from "react"
import { Redirect, RouteComponentProps } from "react-router-dom"
import LoadingView from "../components/LoadingView"
// import Layout from "./Layout"
// import styled from 'styled-components';

function Shell(props: RouteComponentProps<any>) {
  const { location } = props
  const isServerSide = typeof window === "undefined"
  if (isServerSide) {
    return <LoadingView />
  } else {
    const newLocation = Object.assign({}, location, {
      pathname: location.pathname.split("/app-shell")[0] || "/",
    })
    return <Redirect to={newLocation} />
  }
}

export default Shell
